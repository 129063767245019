/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { ErrorMessage, Field } from 'formik';
import * as intl from 'react-intl-universal';
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Label,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import ImageComponent from 'shared/components/image/ImageComponent';
import SignUpIllustration from 'shared/static/img/sign-up-illustration.svg';
import signUpThumb from 'shared/static/img/thumbs/sign_up_thumb.png';

import styles from '../register-welcome/registerWelcome.module.scss';
import StepDots from '../step-dots/StepDots';
import RegisterPasswordProps from './RegisterPasswordProps';

const RegisterPassword: ReactFC<RegisterPasswordProps> = (
  props: RegisterPasswordProps
) => {
  const {
    onBackClick,
    handleChangeEmail,
    step,
    emailVerifying,
    emailExistRequestError,
    emailError,
  } = props;

  const maxLength = Constraints.MaxFieldLength;
  return (
    <>
      <ModalHeader className="increase-font pb-0 border-bottom-0">
        {intl.get('LBL_CA_WELCOME')}
      </ModalHeader>
      <ModalBody className="pt-0">
        <div className="form-group" style={{ margin: 0 }}>
          {emailExistRequestError && (
            <div className="alert alert-danger" role="alert">
              {emailExistRequestError}
            </div>
          )}
        </div>
        <Row>
          <Col xs="auto" className={styles.image}>
            <ImageComponent
              loading="eager"
              src={SignUpIllustration}
              alt="Confirm Password"
              thumb={signUpThumb}
            />
          </Col>
          <Col>
            <FormGroup>
              <Label htmlFor="email">{intl.get('LBL_CA_WORK_EMAIL')}</Label>
              <Field
                autoComplete="off"
                type="email"
                name="email"
                maxLength={maxLength}
                className="form-control"
                onChange={handleChangeEmail}
              />
              <ErrorMessage
                className="form-error"
                component="label"
                name="email"
              />
              {emailError && <label className="form-error">{emailError}</label>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">{intl.get('LBL_CA_PASSWORD')}</Label>
              <Field
                autoComplete="new-password"
                type="password"
                name="password"
                maxLength={maxLength}
                className="form-control"
              />
              <ErrorMessage
                className="form-error"
                component="label"
                name="password"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="confirmedPassword">
                {intl.get('LBL_CA_CONFIRM_PASSWORD')}
              </Label>
              <Field
                autoComplete="new-password"
                type="password"
                name="confirmedPassword"
                maxLength={maxLength}
                className="form-control"
              />
              <ErrorMessage
                className="form-error"
                component="label"
                name="confirmedPassword"
              />
            </FormGroup>
            <FormGroup>
              <div className="insight-checkbox-group">
                <Label style={{ textTransform: 'none' }}>
                  <span className="insight-checkbox mr-3" data-cy="terms-cb">
                    <Field type="checkbox" name="termsAccepted" />
                    <i className="icon-check" />
                  </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.dreamstartlabs.com/portal/en/kb/articles/dreamstart-labs-data-privacy-policy"
                  >
                    {intl.get('LBL_CA_I_ACCEPT_TERMS_AND_CONDITIONS')}
                  </a>
                </Label>
                <ErrorMessage
                  className="form-error"
                  component="label"
                  name="termsAccepted"
                />
              </div>
            </FormGroup>
            <FormText>
              {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.dreamstartlabs.com/"
              >
                {intl.get('LBL_CA_CONTACT_SUPPORT')}
              </a>
            </FormText>
          </Col>
        </Row>
        <Row className="mt-3 pt-3">
          <Col xs="auto">
            <Button disabled={emailVerifying} onClick={onBackClick}>
              {intl.get('BTN_CA_BACK')}
            </Button>
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <StepDots count={3} step={step} />
          </Col>
          <Col xs="auto">
            <Button color="primary" type="submit" disabled={emailVerifying}>
              {intl.get('BTN_CA_CONTINUE')}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </>
  );
};

export default RegisterPassword;
